@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Sofia+Sans&display=swap');
body{
    background: #0A192F;
    color: white;
    font-family: 'Sofia Sans', sans-serif;
}
::-webkit-scrollbar{
    width: 9px;
}
::-webkit-scrollbar-thumb{
    background: linear-gradient(transparent,#239066);
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
::-webkit-scrollbar-thumb:hover{
    background:linear-gradient(transparent,#3be6be);
}